.o-modal {
  $root: &;
  left: rem(8);
  outline: 0;
  position: absolute;
  right: rem(8);
  top: 50%;
  transform: translateY(-80%);

  &_container {
    animation: roadRunnerIn 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    border: 3px solid #bcdfff;
    border-radius: rem(16);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    max-width: rem(360);
    overflow: auto;
    padding-bottom: rem(36);
    padding-top: rem(36);
    position: relative;
  }

  &_content {
    h2 {
      color: #00609c;
      text-align: center;
      @include font-base(20, 32);
    }
  }

  .back {
    display: flex;
    justify-content: center;
    margin-top: rem(26);
    position: relative;

    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      width: rem(180);
    }

    span {
      color: #00609c;
      font-family: inherit;
      font-weight: 700;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      @include font-base(16, 24);
    }
  }
}

.o-modal-portal {
  &-open {
    opacity: 1;
  }
  /* Add modifiers */ 
  // &-bg-antiflashWhite {
  //   .o-modal_container {
  //     // background: $antiflashWhite;
  //   }
  // }
}

.reactmodal-body-open,
.reactmodal-html-open {
  max-height: 100vh;
  overflow: hidden;
}


@keyframes roadRunnerIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
