@mixin icon-image($width: 24, $height: 24, $url: '') {
  background-image: url($url);
  height: rem($height);
  width: rem($width);
}

.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;

  &-loading {
    @include icon-image(24, 24, '~assets/icons/loading.svg');
  }

  &-arrowDown {
    @include icon-image(24, 24, '~assets/icons/ic_arrow_down.svg');
  }
}
