.m-pulldown {
  $root: &;
  cursor: pointer;
  position: relative;
  @include font-base(14, 21);

  &_header {
    background-color: rgba(#f6faff, 0.5);
    border: 1px solid #00609c;
    border-radius: rem(8);
    height: rem(40);
    padding: 0 rem(16);
    @include adjust-flex(space-between, center);
  }

  &_chervon {
    margin-left: rem(10);
    transition: transform $transition ease;
  }

  &_options {
    background-color: #f6faff;
    border: 1px solid #00609c;
    border-radius: rem(8);
    left: 0;
    max-height: rem(250);
    opacity: 0;
    overflow: auto;
    padding: rem(5) 0;
    position: absolute;
    top: 110%;
    transform: translateY(rem(-50));
    transition: all $transition ease;
    visibility: hidden;
    width: 100%;
    z-index: 3;
  }

  &_option {
    align-items: center;
    display: flex;
    padding: rem(7) rem(16);

    p {
      flex: 1;
    }

    svg {
      height: rem(16);
      width: rem(16);
    }
  }

  &-open {
    #{$root}_options {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    #{$root}_chervon {
      transform: rotate(180deg);
    }

  }
}
