.p-scan {
  background-image: url('~assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  max-height: fill-available;
  overflow: hidden;
  position: relative;
  width: 100vw;

  .panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: rem(18);
  }

  .logo {
    display: flex;
    justify-content: space-between;
  }

  .nutifood {
    width: rem(70);
  }

  .nnris {
    width: rem(72);
  }

  .box {
    left: 50%;
    margin-top: rem(40);
    position: absolute;
    top: 10%;
    transform: translateX(-50%);
    width: calc(100% - 36px);

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &_camera {
      video {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
