.a-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;

  img {
    display: none;
  }

  &-inherit {
    background-size: inherit;
  }

  &-initial {
    background-size: initial;
  }

  &-contain {
    background-size: contain;
  }

  &-1x1 {
    @include aspect-ratio(1, 1);
  }

  &-3x4 {
    @include aspect-ratio(3, 4);
  }

  &-nutifood {
    @include aspect-ratio(70, 22);
  }

  &-nnris {
    @include aspect-ratio(72, 24);
  }

  &-nnris_big {
    @include aspect-ratio(150, 78);
  }

  &-submit {
    @include aspect-ratio(236, 66);
  }

  &-353x84 {
    @include aspect-ratio(353, 84);
  }

  &-450x84 {
    @include aspect-ratio(450, 84);
  }

  &-360x331 {
    @include aspect-ratio(360, 331);
  }

  &-638x257 {
    @include aspect-ratio(638, 257);
  }
}
