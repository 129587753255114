* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

html,
body {
  font-family: $elle;
  overflow-x: hidden;
}

button {
  &:focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  text-decoration: none;
}

[role='button'],
button {
  cursor: pointer;
}

input {
  word-break: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: rem(540);
  padding-left: rem(16);
  padding-right: rem(16);
}

.adjust-flex {
  @include adjust-flex(space-between,  center);
}
